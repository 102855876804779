.wrapper{
    display: grid;
    padding: 1rem 2rem 2rem;
    &.layout{
        &-single{
            width: 60vw;
            margin:0 auto;
            header{
                margin-bottom: 3.5rem;
            }
            @include media-breakpoint-down(xxl){
                width: 100%;
                padding-left: calc(#{$spacer} * 1);
                padding-right: calc(#{$spacer} * 1);
            }

            section + section{
                margin-top: calc(#{$spacer} * 2);
            }
        }
        
        &-sidenav{
            gap: 3rem;
            grid-template-columns: 15rem minmax(25rem, 1fr);
            max-width: 100%;
            @include media-breakpoint-down(lg){
                display:block;
                aside{
                    display:none;
                }
                padding: 1rem;
            }
            &.with-toc{
                grid-template-columns: 15rem minmax(25rem, 1fr) 15rem;
            }
        }
    }
    &-fluid{
        header{
            &.header-overview{
                margin-bottom: calc(#{$spacer} * 2);
                input{
                    width: 30rem;
                    @include media-breakpoint-down(lg){
                        width: 100%;
                    }
                }
            }
        }
    }
}
$columns: 2, 3, 4;

@each $column in $columns{
    .grid-column-#{$column}{
        grid-template-columns: repeat(#{$column}, 1fr);
    }
}

.grid-wrapper{
    display: grid;
    padding: 2rem;
}



@mixin steps-size($size){
    font-size: $size;
    min-height: calc(#{$size} * 2rem);
    .stepper-item{
        &:not(:first-child)::before{
            height: $steps-divider-height;
            width: 100%;
            bottom: 0;
            left: -50%;
            top: #{$size};
        }
        .stepper-marker{
            height: calc(#{$size} * 2);
            width: calc(#{$size} * 2);
            position: absolute;
            left: calc(50% - #{$size});
            .icon *{
                font-size: $size;
            }
        }
        .stepper-detail{
            margin-top: calc(#{$size} * 2);
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }
}

.sgds{
    &.stepper{
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
        display: flex;
        flex-wrap: wrap;
        .stepper-item{
            margin-top: 0;
            position: relative;
            flex-grow: 1;
            flex-basis: 0;
            &:not(:first-child){
                flex-basis: 1em;
                flex-grow: 1;
                flex-shrink: 1;
                &::before{
                    content: " ";
                    position: absolute;
                }
            }
            &.is-clickable{
                cursor: pointer;
                &:hover{
                    .stepper-marker{
                        border-color: $purple-600;
                        background-color: $purple-600;
                        
                    }
                    .stepper-detail > *,
                    .stepper-detail{
                        color: $purple-600;
                        transition: all 0.5s ease;
                    }
                }
            }
            &::before{
                background: linear-gradient(to left, $steps-default-color 50%, $steps-active-color 50%);
                background-size: 200% 100%;
                background-position: right bottom;
                .stepper-marker{
                    color: $white;
                }
            }
            &.is-active{
                &::before{
                    background-position: left bottom;
                }
                .stepper-marker{
                    background-color: $white;
                    border-color: $steps-active-color;
                    color: $steps-active-color;
                }
            }
            &.is-completed{
                &::before{
                    background-position: left bottom;
                }
                .stepper-marker{
                    color: $white;
                    background-color: $steps-completed-color;
                    border-color: $steps-completed-color;
                }
            }
            > .stepper-marker{
                align-items: center;
                display: flex;
                border-radius: 50%;
                font-weight: $font-weight-bold;
                justify-content: center;
                background: $steps-maker-default-color;
                color: $white;
                border: $steps-marker-default-border;
                z-index: 1;
            }
            > .stepper-detail{
                text-align: center;
            }
        }

        @include steps-size($font-size-base);
        &.is-small{
            @include steps-size($font-size-sm);
        }
        
        &.is-large{
            @include steps-size($font-size-lg);
        }
    }
}
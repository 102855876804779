@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";

.sgds {
    &.navbar {
        padding: 0 calc(#{$spacer * 2}) 0;
        @include media-breakpoint-down(lg){
            padding: 0 $navbar-mobile-padding-y 0;
        }
        min-height: $navbar-min-height;
        align-items: stretch;

        a.navbar-brand {
            display: flex;
            align-items: center;
            padding-bottom: $navbar-border-bottom-height;
        }

        .navbar-collapse {
            align-items: stretch;
        }

        .nav-item {
            &.has-megamenu {
                position: static;
            }

            a.nav-link {
                color: #344054;
                border-bottom: $navbar-border-bottom-height solid transparent;
                min-height: 100%;
                display: flex;
                align-items: center;

                &.active,
                &:hover {
                    color: $primary;
                    border-color: $primary;
                }

                &.dropdown-toggle {
                    gap: 0.75rem;

                    &.show {
                        color: $primary;
                        border-bottom: $navbar-border-bottom-height solid $primary;
                    }
                }
            }
        }

        .dropdown-menu {
            margin-top: 0;
            border: 1px solid rgba($black, .1);
            border-radius: 0 0 5px 5px;
            background-color: $white;
            box-shadow: 0 .5rem 1rem rgba($black, .15);

            &.megamenu {
                left: 0;
                right: 0;
                width: 100%;
            }
        }
    }
}
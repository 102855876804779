:root{
    --sgds-font-sans-serif: "Inter", system-ui, -apple-system, "Segoe UI", Roboto, Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --sgds-masthead-font-family: var(--sgds-font-sans-serif);
}

body,html{
    overflow-x: clip;
    width: 100%;
}

body{
    position: relative;
    padding-right: 0 !important
}

*:target {
    scroll-margin-top: 140px;
}

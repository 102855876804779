.sgds{
    &.datepicker{
        font-size: $font-size-base;
        width: 24rem;
        max-width: 24rem;
        border-color: $gray-400;
        > .datepicker-header{
            padding: 1.5rem 1.5rem 0;
            border: none;
            color: $primary;
            button{
                background-color: transparent;
                color: $primary;
                border: none;
                font-weight: $font-weight-bold;
                &:focus-visible {
                    outline: $primary auto 2px;
                    z-index: 100;
                }
                &.disabled{
                    cursor: not-allowed;
                }
                &:hover:not(.disabled) {
                    background-color: $purple-100;
                }
              
            }
            i{
                font-size: $font-size-lg;
            }
        }
        > .datepicker-body {
            padding: 0 1.5rem 1.5rem;
        }
        td{
            @extend .rounded-0;
            width: 48px;
            height: 48px;
            padding: 0;
            &:focus-visible {
                outline: $primary auto 2px;
                z-index: 100;
            }
            &.disabled {
                cursor: not-allowed;
                @extend .text-muted;
            }
            &[role='button']:hover:not(.disabled) {
                background-color: $purple-100;
                cursor: pointer;
            }
        }

        .monthpicker,
        .yearpicker{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(4,48px);
            justify-content: space-between;
            align-content: space-between;
            padding: 1rem 0 0;
        }
        .month,
        .year{
            padding:0;
            border: 0;
            background-color: transparent;
            cursor: pointer;
            &.active{
                color: $primary;
                background-color: $purple-100;
            }
            &:focus-visible {
                outline: $primary auto 2px;
                z-index: 100;
            }
            &:hover {
                background-color: $purple-100;
            }
        }
    }
}

@import "~bootstrap/scss/toasts";
.sgds {
    &.toast {
        font-size: $font-size-base;
        border-color: $gray-600;
        border-left: $toast-border-left-width solid;
        border-radius: 0 $border-radius $border-radius 0;

        .toast-header {
            padding: calc(#{$toast-padding-y} + 0.5rem) calc(#{$toast-padding-x} + 0.25rem) calc(#{$toast-padding-y});
            border-bottom: 0;

            .btn-close {
                margin: 0;
            }
        }

        .toast-body {
            padding: 0 calc(#{$toast-padding-x} + 0.25rem) calc(#{$toast-padding-y} + 0.5rem);

            button {
                margin-top: 2rem;
            }
        }
    }

    @each $color,
    $value in $theme-colors {
        &.is-#{$color} {
            border-color: $value;

            .toast-header {
                color: $value;
            }
        }
    }
}
@import '~bootstrap/scss/forms/input-group';

.sgds {
  &.input-group {
    .input-group-text {
      .form-check-input {
        margin-top: 0;
      }
    }
  }
}

.sgds {
  &.input-group {
    &[variant="quantity-toggle"] {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
@import '~bootstrap/scss/type';
  p + p {
    margin-top: $paragraph-margin-bottom;
  }
  
  a{
    text-underline-offset: $link-underline-offset;
  }

  a[target="_blank"]:after {
    display: inline-block;
    font-family: bootstrap-icons;
    content: "\F1C5";
    padding-left: calc($spacer * 0.25);
    text-decoration-line: none;
}
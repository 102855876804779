@import "~bootstrap/scss/dropdown";

.sgds {
  &.dropdown{
    .dropdown-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      &::after {
        // disable: caret is not an icon, but a border with properties
        content: none;
      }
    }
  }
  &.dropdown-menu{
    border: 1px solid $border-color;
    padding: 0;
    li{
      a.dropdown-item{
        padding: 1rem;
        &:hover,
        &.active{
          background-color: $blue-600;
          color: $white;
        }
      }
    }
  }
}
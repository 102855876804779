%icon-position {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $input-font-size;
    z-index: 4;
    //3rem == button/ input height
    width: 3rem;
    height: 3rem;
  }
  
.sgds {
    &.form-control-group {
      position: relative;
      display: flex;
      flex-wrap: wrap; // For form validation feedback
      align-items: stretch;
      width: 100%;
  
      > .form-control {
        padding-left: 3rem;
      }
  
      // Bring the "active" form control to the top of surrounding elements
      > .form-control:focus {
        z-index: 3;
      }
  
      .form-control-icon {
        @extend %icon-position;
        &-validate {
          @extend %icon-position;
          left: inherit;
          right: 0;
        }
      }
    }
  }
  
.section{
    padding: var(--section-padding);
    &-xs{
        padding: var(--section-padding-xs);
    }
    &-sm{
        padding: var(--section-padding-sm);
    }
    &-md{
        padding: var(--section-padding);
    }
    &-lg{
        padding: var(--section-padding-lg);
    }
    &-xl{
        padding: var(--section-padding-xl);
    }
}
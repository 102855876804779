@import '~bootstrap/scss/root';
:root{
    --sgds-gutter-x: 1.5rem;

    // custom variables for section helper class _section.scss

    @include media-breakpoint-up(lg) { 
        --section-padding: 3rem 1.5rem;
        --section-padding-xs: 1rem 1.5rem;
        --section-padding-sm: 1.5rem 1.5rem;
        --section-padding-lg: 9rem 1.5rem;
        --section-padding-xl: 18rem 1.5rem;
    }
}
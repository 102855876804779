.sgds{
    &.nav-tabs{
        border-bottom: none;
        gap: 2rem;
        &[variant="tabs-info-toggle"]{
            li.nav-item{
                button.nav-link{
                    padding: 0.75rem;
                    border-radius: $border-radius;
                    color: $body-color;
                    background-color: $white;
                    border: 1px solid $gray-400;
                    min-width: 11.875rem;
                    .tabs-info{
                        &-label{
                            display:flex;
                            text-align:right;
                            justify-content: flex-end;
                            &.has-icon{
                                justify-content: space-between;
                            }
                        }
                        &-count{
                            text-align:right;
                        }
                    }
                    &.active,&:hover{
                        border-color: $primary;
                        color: $primary;
                        font-weight: $font-weight-bold;
                    }
                }
            }
        }
        &[variant="tabs-basic-toggle"]{
            gap: 0;
            li.nav-item{
                
                + li.nav-item{
                    margin-left: -2px;
                }
                button.nav-link{
                    padding: initial;
                    border: 1px solid $gray-400;
                    border-radius: 0;
                    padding: 0.5rem 1.5rem;
                    color: $body-color;
                    background-color: $white;
                    i,span{
                        &.left{
                            margin-right: $nav-sgds-tabs-icon-padding;
                        }
                        &.right{
                            margin-left: $nav-sgds-tabs-icon-padding;
                        }
                    }
                    &.active,&:hover{
                        color: $white;
                        background-color: $link-color;
                    }
                }
            }
        }
        &:not([variant="tabs-basic-toggle"]):not([variant="tabs-info-toggle"]){
            li.nav-item{
                button.nav-link{
                    padding-top: 0;
                    padding-left: 0;
                    padding-right: 0;
                    background-color: transparent;
                    color: $body-color;
                    border-color: transparent;
                    border: none;
                    &.active{
                        border-bottom: $navbar-border-bottom-height solid $link-color;
                        font-weight: $font-weight-bold;
                        background-color: transparent;
                    }
                }
            }
        }
    }
    &.tab-content{
        padding-top: $nav-sgds-tabs-padding-y;
        padding-bottom: $nav-sgds-tabs-padding-y;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

.w-75ch{
    width: 75ch;
}

body{
    color: var(--sgds-gray-600);
}

.title{
    font-weight: bold;
}

p,li{
    color: var(--sgds-gray-600)
}

h1{
    letter-spacing: -0.02em;
}

sgds-masthead{
    display: block;
}

.subtitle{
    color: var(--sgds-gray-600);
}


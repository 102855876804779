@import '~bootstrap/scss/reboot';

h1 {
  
  @include header-line-height($h1-line-height);
}

h2 {
  
  @include header-line-height($h2-line-height);
}

h3 {
  
  @include header-line-height($h3-line-height);
}

h4 {
  
  @include header-line-height($h4-line-height);
}

h5 {
  
  @include header-line-height($h5-line-height);
}

h6 {
  
  @include header-line-height($h6-line-height);
}
aside{   
    &:not(.toc-container){
        background-color: var(--sgds-gray-100);
        margin: -1rem 0rem -2rem -2rem;
    }

    .sgds.sidenav{
        padding: 1rem;

        h4 {
            margin-bottom: 0;
            padding: 0rem 0.5rem 0.5rem;
        }
       
        a{
            text-decoration: none;
            color: var(--sgds-gray-600);
            &:hover{
                color: var(--sgds-primary);
            }
        }

        .btn.active {
            a {
                color: var(--sgds-primary);
            }
        }

        .btn:hover {
            a {
                color: var(--sgds-primary);
            }
        }
        
        .sidenav-item {
            .btn.active, .btn.inactive {
                i.bi-chevron-down  {
                    transform: rotate(180deg);
                }
            }
        }

        .sidenav-item {
            .btn.active, .btn.inactive {
                i.bi-chevron-down  {
                    &.collapsed {
                        transform: rotate(0deg);
                    }
                }
            }
        }

        .sidenav-item > ul {
            list-style: none;
            padding-left: 0;
            &.collapse, &.collapse.show, &.collapsing {
                margin-top: 0;
            }

            li.active {
                color: var(--sgds-primary);
                font-weight: 700;
            }

            li {
                .sgds.btn {
                    border-left: 0;
                    padding-left: 2rem;
                    gap: 0;
                }
            }
        }

        .sidenav-item + .sidenav-item {
            margin-top: 0;
        }
    }
}
@import "~bootstrap/scss/tooltip";
.sgds{
    &.tooltip{
        position: absolute;
        .tooltip-inner{
            display: flex;
            gap: 2rem;
            text-align: left;
        }
    }
}
.get-started {
    width: 120ch;
}

.get-started-content-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    @include media-breakpoint-down(xl) {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }
    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
    }

    .card {
        @extend .portal-borderless-card;
        border-radius: 1.5rem;
        &.disabled {
            opacity: 0.5;
        }
    }
}

.get-started-overview-grid {
    grid-template-columns: repeat(2, 1fr);
    @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .card {
        &-body {
            @extend .portal-borderless-card-body;
            padding: 2rem 3.125rem;
        }
    }
}

@import "~bootstrap/scss/card";
.sgds {
    &.card {
        a:not(.btn) {
            font-weight: $font-weight-bold;
        }
        &[variant*="card-action"] {
            transition-property: box-shadow, border-color;
            transition-duration: $card-transition-duration;
            transition-timing-function: $card-transition-timing-function;
            .card-body {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                i {
                    font-size: 1.5rem;
                    margin-right: 1rem;
                }
                > * {
                    margin-bottom: 0;
                }
            }
            &:hover,
            &.is-active {
                border-color: transparent;
                box-shadow: $box-shadow, $card-active-border;
            }
            .card-subtitle {
                display: flex;
                justify-content: space-between;
                align-items: center;

                div {
                    display: flex;
                    align-items: center;
                }
            }
            .card-input {
                input.form-check-input {
                    margin-top: 0;
                    min-width: 1.5rem;
                    min-height: 1.5rem;
                }
            }
        }
        &[variant="card-action-quantity-toggle"] {
            .card-body {
                display: flex;
                flex-direction: column;
                gap: 2rem;
            }
            div:not([class]) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .btn {
                    margin-top: 0;
                }
                .input-group {
                    margin-top: 0;
                    .btn i {
                        margin-right: 0;
                    }
                    input,
                    button {
                        margin-top: 0;
                    }
                }
                .card-unit {
                    flex-basis: 150%;
                }
                &:last-child {
                    @media (max-width: 500px) {
                        div.card-unit {
                            margin-bottom: 1rem;
                        }
                        display: block;
                    }
                }
            }
        }
    }
}

@import "~bootstrap/scss/alert";

@each $state, $value in $theme-colors-set {
    
    $alert-background: map-get($map: $value , $key: #{$state}-100);
    $alert-border: map-get($map: $value , $key: #{$state}-400);
    $alert-color: $gray-600;

    .alert-#{$state} {
      @include alert-variant($alert-background, $alert-border, $alert-color);

      i{
        color: map-get($map: $value , $key: #{$state}-600)
      }
    }
  }

.sgds{
  &.alert{
    > i{
      @extend .align-self-start;
    }
  }
}
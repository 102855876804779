
.sgds{
    &.combobox {
        @extend .form-control-group;
        justify-content: flex-end;

        > .form-control {
            padding-left: $input-btn-padding-x;
            padding-right: 3rem;
        }

        > .dropdown-menu {
            min-width: $combobox-menu-min-width;
        }
        &.scrollable {
            > .dropdown-menu {
                max-height: $combobox-scroll-menu-max-height;
                overflow: auto;
            }
           
        }
    }
}